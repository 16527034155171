<template>
    <div class="addVue">
        <div class="contact_title_box" v-if="index == 0">
            <!-- 联系人 -->
            <div class="contact_title">{{$t('mx_mobile.common.1665818691849')}}</div>
            <div class="add-contact">
                <!-- 添加 -->
                <van-button type="primary" @click="addContact" size="small">{{$t('mx_mobile.common.1665817395413')}}</van-button>
            </div>
        </div>
        <div class="contact_title_box">
            <!-- 联系人 -->
            <div class="contact_title">{{$t('mx_mobile.common.1665818691849')}}_{{index+1}}</div>
            <div v-if="index > 0">
                <!-- 删除 -->
                <van-button type="primary" @click="deleteContact" size="small">{{$t('mx_mobile.Client.1585301969072')}}</van-button>
            </div>
        </div>
        <template v-for="(item, index) in editSet">
            <div :key="index" class="list">
                <template v-if="item.fieldGroup == 0">
                    <template v-if="item.controlTypeId == 0||item.controlTypeId == 14">
                        <p class="contactTitle" :key="index">{{ item.cnFieldCaption }}</p>
                    </template>
                    <template v-else-if="item.controlTypeId != 84 && item.controlTypeId != 127">
                        <div class="listBox">
                            <component :sysBoxValue="sysBoxValue" :type="type" @returnFieldList="returnFieldList" :cusBoxValue="cusBoxValue" :dataId="'control'+index" :nameId="item.fieldName" :optCode="optCode" :moduleCode="moduleCode" :key="index" ref="control" v-bind:is="'control'+item.controlTypeId" :itemData="item" :checkRepeat="true"></component>
                        </div>
                    </template>
                </template>
                <template v-else>
                    <div class="listBox">
                        <component  :sysBoxValue="sysBoxValue" :optCode="optCode" :type="type" :moduleCode="moduleCode" :key="index" :dataId="'control'+index" :cusBoxValue="cusBoxValue" v-bind:is="'group'+item.fieldGroup" ref="controlGroup" :itemData="item"></component>
                    </div>
                </template>
            </div>
        </template>
    </div>
</template>
<script>
import Controls from '@/page/Client/CustPublic/Controls/index.js'
import GroupControls from '@/page/Client/CustPublic/GroupControls/index.js'
export default {
    name: 'addVue',
    props: {
        editSet: {
            type: Array,
            default: function () {
                return []
            }
        },
        moduleCode: {
            type: String,
            default: ''
        },
        optCode: {
            type: String,
            default: 'otNew'
        },
        sysBoxValue: {
            type: Array,
            default: () => {
                return []
            }
        },
        cusBoxValue: {
            type: Array,
            default: () => {
                return []
            }
        },
        type: {
            type: String,
            default: 'otNew'
        },
        itemData: {
            type: Object,
            default: () => {
                return {}
            }
        },
        index: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
        }
    },
    methods: {
        addContact() {
            this.$emit('addContact')
        },
        deleteContact() {
            this.$emit('deleteContact', this.index)
        },
        returnFieldList(data, field) {
            let fieldList = field.split(';')
            fieldList.forEach(element => {
                if (element != '') {
                    let elementList = element.split('=')
                    if (elementList[0] && elementList[1]) {
                        this.$refs['control'].forEach((items) => {
                            if (items.$attrs.nameId == elementList[0]) {
                                let value = data[elementList[1]]
                                items.updates(value)
                            }
                        })
                    }
                }
            })
        },
        update(obj) {
            let obj1 = JSON.parse(obj)
            if (this.Global.utils.isArray(obj1)) {
                this.$refs['control'].forEach((item) => {
                    obj1.forEach(element => {
                        if (element.key == item.$attrs.nameId) {
                            item.updates(element.value)
                        }
                    })
                })
            } else {
                this.$refs['control'].forEach((item) => {
                    if (obj1.key == item.$attrs.nameId) {
                        item.updates(obj1.value)
                    }
                })
            }
        },
        updates(obj) {
            let obj1 = JSON.parse(obj)
            setTimeout(() => {
                this.$refs['control'].forEach((item) => {
                    if (obj1[item.$attrs.nameId]) {
                        item.updates(obj1[item.$attrs.nameId])
                    }
                })
                this.$refs['controlGroup'].forEach((item) => {
                    item.updates(obj1)
                })
            }, 20)
        },
        submit() {
            let data = {}
            let isCheck = true
            if (this.$refs['control']) {
                this.$refs['control'].forEach((item) => {
                    let itemData = item.submit && item.submit()
                    if (itemData) {
                        data = Object.assign(data, itemData)
                    } else {
                        isCheck = false
                        return false
                    }
                })
            }
            if (this.$refs['controlGroup']) {
                this.$refs['controlGroup'].forEach((item) => {
                    let itemData = item.submit && item.submit()
                    if (itemData) {
                        data = Object.assign(data, itemData)
                    } else {
                        isCheck = false
                        return false
                    }
                })
            }
            if (isCheck) {
                return data
            } else {
                return false
            }
        }
    },
    components: Object.assign({}, Controls, GroupControls)
}
</script>

<style lang="less" scoped>
.addVue {
    .margin-bottom(10px);
    width: 100%;
    background: #fff;
    .contact_title_box {
        display: flex;
        justify-content: space-between;
        padding: 0 15px;
        background: #f5f5f5;
        .height(45px);
        .line-height(45px);
    }
    .list {
        .listBox {
            .padding-left(0);
            .margin-left(16px);
            .min-height(45px);
            .line-height(45px);
            &.listBoxV1 {
                .padding-left(16px);
                .margin-left(0px);
            }
            border-bottom: 1px solid #eaedef;
            &.noPaddingLeft {
                padding-left: 0px;
            }
        }
        .contactTitle {
            .height(45px);
            .line-height(45px);
            background: #f5f5f5;
            .padding-left(16px);
            .font-size(14px);
        }
    }
    .list:last-child {
        .listBox {
            border-bottom: 0;
        }
    }
    .next__follow {
        color: #909399;
        border-bottom: 1px solid #eaedef;
        .padding-left(16px);
        .next__follow-time {
            .height(30px);
            .line-height(30px);
            .padding-right(16px);
            .name {
                display: inline-block;
                .width(100px);
            }
        }
        .next__follow-desc {
            .padding-left(100px);
            &:lang(en) {
                .padding-left(130px);
            }
            .padding-right(16px);
            /deep/ .van-cell {
                .padding(10px, 0px);
            }
        }
    }
    .files__ware {
        .list {
            vertical-align: top;
            .width(75px);
            .height(75px);
            display: inline-block;
            .margin(5px);
            .margin-left(0);
            .margin-right(10px);
            line-height: 75px;
            text-align: center;
            position: relative;
            > img {
                .width(75px);
                .height(75px);
                vertical-align: top;
            }
            .iconBox {
                position: absolute;
                .width(19px);
                .line-height(14px);
                .height(19px);
                background: rgba(0, 0, 0, 0.6);
                border: 2px solid rgba(255, 255, 255, 1);
                border-radius: 50%;
                color: white;
                .font-size(12px);
                font-weight: bold;
                .right(-4px);
                .top(-4px);
            }
        }
        /deep/ .van-cell {
            .padding(10px, 16px, 10px, 0px);
        }
        /deep/ .van-collapse-item__content {
            .padding(0px, 16px, 0px, 0px);
        }
        /deep/ .van-hairline--top-bottom::after,
        .van-hairline-unset--top-bottom::after {
            border: none;
        }
        .AppItem {
            .padding-left(16px);
            .font-size(14px);
            border-bottom: 1px solid #eaedef;
        }
        .show-img-annex {
            font-size: 0;
            .iconBox-ware {
                position: relative;
                display: inline-block;
                width: 100%;
                .margin-right(10px);
                .margin-bottom(5px);
                .height(40px);
                font-size: 0;
                border: 1px solid #eaedef;
                .icon-img {
                    .width(25px);
                    .height(25px);
                    .margin-left(5px);
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    .iconIMG,
                    .iconSVG {
                        position: absolute;
                        top: 50%;
                        left: 0;
                        transform: translateY(-50%);
                        display: inline-block;
                        vertical-align: middle;
                        .width(25px);
                        .height(25px);
                    }
                }
                .iconfont {
                    color: #65585a;
                    position: absolute;
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%);
                }
                .contentBox {
                    position: absolute;
                    right: 0;
                    top: 0;
                    display: inline-block;
                    vertical-align: middle;
                    overflow: hidden;
                    width: calc(100% - 2rem);
                    height: 100%;
                    .margin-left(5px);
                    .content {
                        width: 100%;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        & > p {
                            .height(17px);
                            .line-height(17px);
                            .font-size(12px);
                        }
                        .fileName {
                            color: #909399;
                            /*  .margin-bottom(8px); */
                        }
                        .fileInfo {
                            color: #909399;
                        }
                    }
                }
                &:last-child {
                    .contentBox {
                        border-bottom: unset !important;
                    }
                }
            }
        }
    }
    .upload_button {
        .height(45px);
        position: fixed;
        bottom: 0;
        left: 0;
        border-top: 1px solid #eaedef;
        background: #fff;
        display: inline-block;
        width: 100%;
        .padding(0px, 16px);
        .upload_file {
            .margin-right(10px);
            color: #008cee;
            cursor: pointer;
            display: inline-block;
            .width(20px);
            .line-height(45px);
            .font-size(20px);
        }
    }
}
</style>
