<template>
    <div class="title-box">
        <div class="cust-wrap" style="display: flex;">
            <div class="listTitle">
                <!-- 客 -->
                <div v-if="itemData['custId']" class="Tag custTag">{{$t('mx_mobile.common.1665811542372')}}</div>
                <div v-else class="Tag strangeTag">
                    <!-- 陌 -->
                    {{$t('mx_mobile.common.1665819404439')}}
                </div>
                <div class="ellipsis">{{itemData.custName}}</div>
            </div>
            <div v-if="isFocusBill(itemData.focusTargets)" class="starBox">
                <i class="iconfont icon-star" style="font-size:16px;"></i>
            </div>
        </div>
        <div class="title-wrap">
            <!-- 线索接收人 -->
            <span class="fieldname">{{$t('mx_mobile.common.1665813538051')}}:</span>
            <span>{{itemData._convertData.ownerCtId}}</span>
            <span style="margin-left: 15px;">{{itemData._convertData.ownerDeptKey}}</span>
        </div>
        <div class="title-wrap">
            <!-- 线索编号 -->
            <span class="fieldname">{{$t('mx_mobile.common.1665813488934')}}:</span>
            <span>{{itemData.intentCode}}</span>
        </div>
        <div class="comments-box">
            <div v-for="item in comments" :key="item.commentId">
                <i class="iconfont icon-dot" :style="setCommentsColor(item.commentFlag)"></i>
                <span>{{item.content}}</span>
            </div>
        </div>
        <div class="optListBox">
            <template v-for="(item, index) in clueProcess">
                <list-item @getData="getData" :showLeft="index > 0" :showRight="index < clueProcess.length-1" :ownerCtId="itemData.ownerCtId" :optCode="optCode" :key="index" :orderNumber="index + 1" :item="item" :intentLevel="itemData.intentLevel" :length="clueProcess.length"></list-item>
            </template>
        </div>
        <div class="labelBox">
            <template v-for="items in itemData.tags">
                <span class="label ellipsis" v-if="items!=''&&returnTag(items)!=''" :key="items">
                    {{returnTag(items)}}
                </span>
            </template>
        </div>
    </div>
</template>
<script>
import processItem from './processItem.vue'
export default {
    name: 'clueTitle',
    props: {
        moduleCode: {
            type: String,
            default: ''
        },
        optCode: {
            type: String,
            default: 'otNew'
        },
        sysBoxValue: {
            type: Array,
            default: function () {
                return []
            }
        },
        cusBoxValue: {
            type: Array,
            default: function () {
                return []
            }
        },
        itemData: {
            type: Object,
            default: () => { }
        },
        listLabelsMap: {
            type: Object,
            default: () => { }
        }
    },
    data() {
        return {
            clueProcess: [
                {
                    'nodeName': this.$t('mx_mobile.common.1665823507314'), // '常规线索'
                    'intentLevel': 1
                },
                {
                    'nodeName': 'MQL',
                    'intentLevel': 2
                },
                {
                    'nodeName': 'SQL',
                    'intentLevel': 3
                },
                {
                    'nodeName': this.$t('mx_mobile.common.1665823589713'), // '转化商机'
                    'intentLevel': 4
                }
            ]
        }
    },
    computed: {
        comments() {
            return this.itemData?.comments || []
        }
    },
    components: {
        'list-item': processItem
    },
    methods: {
        returnTag(tagId) {
            let labelName = ''
            labelName = this.listLabelsMap?.[tagId].labelName
            return labelName
        },
        getData() {
            this.$emit('getData')
        },
        isFocusBill(list) {
            if (!list) {
                return false
            }
            let ctId = this.companie.ctId
            let focus = false
            for (let index = 0; index < list.length; index++) {
                if (ctId == list[index]) {
                    focus = true
                }
            }
            return focus
        },
        setCommentsColor(flag) {
            return this.Global.utils.commentsColor(flag)
        }
    }
}
</script>

<style lang="less" scoped>
.title-box {
    width: 100%;
    padding: 0 10px 10px;
    color: #909399;
    background: #fff;
    min-height: 157px;
    .comments-box {
        font-size: 12px;
        margin: 10px 0;
        .iconfont {
            margin-right: 10px;
        }
    }
    .labelBox {
        display: flex;
        flex-wrap: wrap;
        .label {
            padding: 1px 5px;
            color: #ff7165;
            border: 1px solid #ff7165;
            border-radius: 3px;
            margin-left: 5px;
            margin-bottom: 5px;
        }
    }
    .title-wrap,
    .cust-wrap {
        height: 25px;
        line-height: 25px;
        display: flex;
        .fieldname {
            width: 100px;
        }
    }
    .cust-wrap {
        justify-content: space-between;
        height: 30px;
        line-height: 30px;
    }
    .listTitle {
        .height(30px);
        .line-height(30px);
        position: relative;
        .padding-right(166px);
        display: flex;
        align-items: center;
    }
    .Tag {
        font-size: 12px;
        width: 20px;
        height: 20px;
        color: #fff;
        border-radius: 50%;
        text-align: center;
        line-height: 20px;
        margin-right: 8px;
    }
    .strangeTag {
        background-color: #606266;
    }
    .custTag {
        background-color: #d0021b;
    }
    .starBox {
        color: #ffb735;
    }
    .optListBox {
        padding-bottom: 10px;
    }
}
</style>
