<template>
    <div class="detailPage">
        <clue-title :itemData="itemData" :listLabelsMap="listLabelsMap"></clue-title>
        <van-tabs class="vanTabs">
            <van-tab v-for="item in tabList" :title="item.cnStrucName" :key="item.component">
                <component v-bind:is="item.component" :editSet="editSet" :itemData="itemData" :customerFields="customerFields" :contactFields="contactFields" :socials="socials"></component>
            </van-tab>
        </van-tabs>
    </div>
</template>
<script>
import clueTitle from './clueTitle/index.vue'
import titleMixin from '@/mixin/title'
import contact from './contact/index.vue'
import goodsList from './goodsList/index.vue'
import baseMsg from './baseMsg/index.vue'
import clueGoodsmsg from './clueGoodsmsg/index.vue'
import operationRecord from './operationRecord/index.vue'
import { mapGetters, mapActions } from 'vuex'
export default {
    name: 'NewAndEdit',
    mixins: [titleMixin],
    title: function fc() { return this.$t('mx_mobile.common.1665802470469') }, // '线索'
    computed: {
        ...mapGetters(['sysBoxValue', 'unitList'])
    },
    data() {
        return {
            loading: true,
            moduleCode: '',
            editSet: [],
            optCode: 'otNew',
            isEditShowSet: [],
            customerFields: [],
            ownerFields: [],
            filingFields: [],
            isEditShowSetcustomer: [],
            customerArr: [],
            contactFields: [],
            strucId2Data: [],
            contactObj: {},
            goodsType: {},
            goodsFields: [],
            classTypeInUseList: [],
            id: '',
            itemData: {},
            listLabelsMap: {},
            socials: [],
            tabList: Object.freeze([
                {
                    cnStrucName: this.$t('mx_mobile.Client.1585276644929'), // '详情'
                    moduleCode: 'PS003',
                    component: 'base-msg'
                },
                {
                    cnStrucName: this.$t('mx_mobile.common.1665817307774'), // '关联商品'
                    moduleCode: 'PS003',
                    component: 'clue-goodsmsg'
                },
                // {
                //     cnStrucName: '附件',
                //     moduleCode: 'SC017',
                //     component: 'attachment'
                // },
                {
                    cnStrucName: this.$t('mx_mobile.common.1665820049688'), // '操作记录'
                    moduleCode: 'SC017',
                    component: 'operation-record'
                }
                // {
                //     cnStrucName: '往来邮件',
                //     moduleCode: 'PS003',
                //     component: 'contacts-mail'
                // },
                // {
                //     cnStrucName: '相关团队',
                //     moduleCode: 'PS003',
                //     component: 'team'
                // },
                // {
                //     cnStrucName: '动态',
                //     moduleCode: 'PS003',
                //     component: 'Dynamic'
                // }
            ])
        }
    },
    mounted() {
        if (this.sysBoxValue && this.sysBoxValue.length == 0) {
            this.setSysBoxValue()
        }
        if (this.unitList && this.unitList.length == 0) {
            this.setUnitList()
        }
        let { moduleCode, id } = this.$route.params
        this.moduleCode = moduleCode
        this.itemId = id
        this.setMenu()
        this.getData()
    },
    methods: {
        // 个人信息存起来
        ...mapActions([
            'setSysBoxValue', 'setUnitList'
        ]),
        setMenu() {
            let rightMenu = this.Global.utils.rightMenu.setMenu('')
            rightMenu.onClick(() => {
            })
        },
        async getClassType() {
            let list = []
            try {
                let url = this.Global.config.apiBaseURL + this.Global.api.v2.document_product_category
                let res = await this.axios.get(url, {
                    params: {
                        type: 'inUse'
                    }
                })
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    this.loading = false
                    list = res.data.data.list
                } else {
                    this.loading = false
                    this.$toast.fail(res.data.msg)
                }
            } catch (error) {
                this.loading = false
                this.$toast.fail(this.Global.config.errorTitle)
            }
            return list
        },
        async _getSocialType() {
            let result = {}
            try {
                const url = this.Global.config.apiBaseURL + this.Global.api.v2.socialType_get
                let res = await this.axios.get(url, {
                    params: {}
                })

                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    this.loading = false
                    result = res.data.data || {}
                } else {
                    this.loading = false
                    this.$toast.fail(res.data.msg)
                }
            } catch (err) {
                this.loading = false
                this.$toast.fail(this.Global.config.errorTitle)
            }
            return result
        },
        async _getItemData() {
            let result = {}
            try {
                const url = this.Global.config.apiBaseURL + this.Global.api.v2.document_quotation_get
                let res = await this.axios.get(url, {
                    params: {
                        moduleCode: this.moduleCode,
                        searchType: 'detail',
                        strucId: '1',
                        identFieldValue: this.itemId
                    }
                })

                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    this.loading = false
                    result = res.data.data || {}
                } else {
                    this.loading = false
                    this.$toast.fail(res.data.msg)
                }
            } catch (err) {
                this.loading = false
                this.$toast.fail(this.Global.config.errorTitle)
            }
            return result
        },
        async _fieldShow_get(strucId) {
            let result = {}
            try {
                const url = this.Global.config.apiBaseURL + this.Global.api.v2.fieldShow_get
                let res = await this.axios.get(url, {
                    params: {
                        moduleCode: this.moduleCode,
                        type: 'addEditSet',
                        strucId: strucId
                    }
                })

                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    this.loading = false
                    result = res.data.data || {}
                } else {
                    this.loading = false
                    this.$toast.fail(res.data.msg)
                }
            } catch (err) {
                this.$toast.fail(this.Global.config.errorTitle)
            }
            return result
        },
        async _getLabelList() {
            let result = {}
            try {
                const url = this.Global.config.apiBaseURL + this.Global.api.v2.label_get
                let res = await this.axios.get(url, {
                    params: {
                        moduleCode: this.moduleCode,
                        searchType: 'list'
                    }
                })

                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    this.loading = false
                    result = res.data.data || {}
                } else {
                    this.loading = false
                    this.$toast.fail(res.data.msg)
                }
            } catch (err) {
                this.$toast.fail(this.Global.config.errorTitle)
            }
            return result
        },
        getData() { // 获取数据
            Promise.all([this._fieldShow_get(1), this._fieldShow_get(2), this._fieldShow_get(3), this._getItemData(), this._getLabelList(), this._getSocialType()]).then(results => {
                this.loading = false
                this.itemData = results[3]
                let editSet1 = results[0]
                let labelList = results[4]
                labelList.forEach(ele => {
                    this.$set(this.listLabelsMap, ele.labelId, ele)
                })
                this.swidchEditSet(editSet1)
                this.socials = results[5]
                console.log(results[5])
                this.contactFields = results[1].filter(ele => ele.isEditShow != 2)
            })
        },
        swidchEditSet(list) {
            this.editSet = []
            let newListBase = list
            let editSet = []
            let fieldCategoryArr = newListBase.filter(item => item.fieldCategory == 4)
            let fieldCategoryObj = {}
            fieldCategoryArr.forEach(ele => {
                let obj = {}
                if (ele.returnFieldList.length > 0) {
                    let arr = ele.returnFieldList.split(';')
                    obj[ele.fieldId] = [...arr]
                }
                Object.assign(fieldCategoryObj, obj)
            })
            let setArrChildFunc = function (num) { // 分离各模块字段num为fieldCategory == 4的fieldId
                let contactSetArr = fieldCategoryObj[num] // fieldId ==40018的是客户联系信息
                let childArr = []
                childArr = newListBase.filter(item => item.fieldId == num)
                newListBase.forEach(ele => {
                    let str = ''
                    if (contactSetArr.length > 0 && contactSetArr[0].split(',').length > 0) {
                        str = contactSetArr[0].split(',')
                    }
                    if (str.includes(ele.fieldId + '')) {
                        childArr.push(ele)
                    }
                })
                return childArr
            }
            let processDataFunc = (list) => { // 处理字段信息，组装主控件字段
                let newLList = []
                let groupFirst = []
                list.forEach((element) => {
                    element.controlData = ''
                    element.disabled = this.fieldIsEditable(this.optCode, element.editState)

                    element.fieldValue = element.inDefaultValue
                    newLList.push(element)
                })
                return newLList
            }
            let baseArr = setArrChildFunc(3210) // 公司信息fieldId ==3210
            editSet = processDataFunc(baseArr)
            this.customerArr = setArrChildFunc(3211) // 3211 客户信息
            let filingFields = []
            this.customerArr.forEach(ele => {
                let obj = JSON.parse(JSON.stringify(ele))
                filingFields.push(obj)
            })
            let customerEditSet = processDataFunc(this.customerArr)
            let filingEditSet = processDataFunc(filingFields)
            // 过滤出isEditShow ==2
            let isEditShowSet = editSet.filter(ele => ele.isEditShow == 2)
            let otherEditShowSet = editSet.filter(ele => ele.isEditShow != 2 && ele.fieldName != 'intentStatus' && ele.fieldName != 'intentLevel' && ele.controlTypeId != 16)
            this.isEditShowSet = isEditShowSet
            this.customerFields = customerEditSet.filter(ele => ele.isEditShow != 2 && ele.fieldName != 'custRelyType' && ele.fieldName != 'custId' && ele.controlTypeId != 16)
            let ownerFields = customerEditSet.filter(ele => ele.isEditShow != 2 && ele.fieldGroup == 31)
            this.ownerFields = Object.freeze(ownerFields)
            this.filingFields = filingEditSet.filter(ele => ele.isEditShow != 2 && ele.fieldGroup != 31)
            this.isEditShowSetcustomer = customerEditSet.filter(ele => ele.isEditShow == 2)
            this.editSet = otherEditShowSet
        }
    },
    watch: {
        // $route(val, old) {
        //     if (val.path.indexOf('/sale/tabs/new/') != -1) {
        //         this.setMenu()
        //     }
        // }
    },
    components: {
        'clue-title': clueTitle,
        'contact': contact,
        'goods-list': goodsList,
        'base-msg': baseMsg,
        'clue-goodsmsg': clueGoodsmsg,
        'operation-record': operationRecord
    }
}
</script>

<style lang="less" scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>
