<template>
    <div class="optItemBox" @click="check" :class="'lineBg' + returnState()" :style="{'margin-left': !showLeft ? '0' : '', 'border-left-width': !showLeft ? '1px' : '', 'border-right-width': !showRight ? '1px' : ''}">
        <div class="box01" :class="'ArrowBigLeft' + returnState()" v-if="showLeft">
            <div class="child" :class="'ArrowSmallLeft' + returnState()"></div>
        </div>
        <!-- <span v-if="returnState() == 1" class="orderIcon">
            <i class="iconfont icon-hook"></i>
        </span>
        <span v-else class="orderIcon">
            {{orderNumber}}
        </span> -->
        <div class="optItemContentBox">
            <div class="contentTit">
                {{item.nodeName}}
            </div>
        </div>
        <div class="box02" :class="'ArrowBigRight' + returnState()" v-if="showRight">
            <div class="child" :class="'ArrowSmallRight' + returnState()"></div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'processItem',
    props: {
        moduleCode: {
            type: String,
            default: ''
        },
        item: {
            type: Object,
            default: () => {
                return {}
            }
        },
        showRight: {
            type: Boolean,
            default: false
        },
        intentLevel: {
            type: [String, Number],
            default: ''
        },
        showLeft: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
        }
    },
    computed: {
    },
    methods: {
        returnState() {
            if (this.item.intentLevel == this.intentLevel && this.item.intentLevel == this.length) {
                return '1'
            } else if (this.item.intentLevel == this.intentLevel) { // 当前状态==就是目前节点
                return '3'
            } else if (this.item.intentLevel < this.intentLevel) { // 当前状态之前的节点
                return '1'
            } else {
                return '2' // 当前状态之后的节点
            }
        },
        check() {
            this.$emit('check', this.item)
        },
        getData() {
            this.$emit('getData')
        }
    }
    }
</script>

<style lang="less" scoped>
.lineBg {
        background: #b0eba6;
        border-color: #b0eba6;
        color: white;
        .orderIcon {
            color: #b0eba6;
            background: white;
        }
    }
    .lineBgNo {
        color: #909399;
        background: #eff2f4;
        border-color: #eff2f4;
        .orderIcon {
            color: white;
            background: #909399;
        }
    }
    .lineBg0,
    .lineBg1 {
        color: #aadbb8;
        border-color: #aadbb8;
        background: #aadbb8;
        .orderIcon {
            color: #aadbb8;
            background: white;
        }
    }
    .lineBg2 {
        color: #c0c4cd;
        border-color: #c0c4cd;
        cursor: pointer;
        .orderIcon {
            color: #c0c4cd;
            background: white;
        }
    }
    .lineBg2 {
        .contentTit {
            color: #c0c4cd !important;
        }
    }
    .lineBg3 {
        background: #2fc25b;
        border-color: #2fc25b;
        color: #2fc25b;
        .orderIcon {
            color: #2fc25b;
            background: white;
        }
    }
    .lineBg4 {
        background: #fff;
        border-color: #8bd866;
        color: #8bd866;
        .orderIcon {
            color: #8bd866;
            background: white;
        }
    }
    .lineBg5 {
        background: rgba(255, 183, 53, 1);
        color: white;
        .orderIcon {
            color: rgba(255, 183, 53, 1);
            background: white;
        }
    }
    .ArrowBigLeft {
        border-top-color: #eff2f4 !important;
        border-bottom-color: #eff2f4 !important;
    }
    .ArrowBigLeftNo {
        border-top-color: #b0eba6 !important;
        border-bottom-color: #b0eba6 !important;
    }
    .ArrowBigLeft0,
    .ArrowBigLeft1 {
        border-color: #c0c4cd;
    }
    .ArrowBigRight {
        border-color: #b0eba6;
    }
    .ArrowBigRightNo {
        border-left-color: #eff2f4 !important;
        border-right-color: #eff2f4 !important;
    }
    .ArrowBigRight0,
    .ArrowBigRight1 {
        border-color: #c0c4cd;
    }
    .ArrowSmallLeft {
        border-top-color: #b0eba6 !important;
        border-bottom-color: #b0eba6 !important;
    }
    .ArrowSmallLeftNo {
        border-top-color: #eff2f4 !important;
        border-bottom-color: #eff2f4 !important;
    }
    .ArrowSmallLeft0,
    .ArrowSmallLeft1 {
        border-top-color: #aadbb8 !important;
        border-bottom-color: #aadbb8 !important;
    }
    .ArrowSmallRight {
        border-left-color: #b0eba6 !important;
    }
    .ArrowSmallRightNo {
        border-left-color: #eff2f4 !important;
    }
    .ArrowSmallRight0,
    .ArrowSmallRight1 {
        border-left-color: #aadbb8 !important;
    }
    .ArrowBigLeft2 {
        border-color: #8bd866;
    }
    .ArrowBigRight2 {
        border-color: #8bd866;
    }
    .ArrowSmallLeft2 {
        border-top-color: white !important;
        border-bottom-color: white !important;
    }
    .ArrowSmallRight2 {
        border-left-color: white !important;
    }
    .ArrowBigLeft3 {
        border-top-color: #2fc25b !important;
        border-bottom-color: #2fc25b !important;
    }
    .ArrowBigRight3 {
        border-color: #8bd866;
    }
    .ArrowSmallLeft3 {
        border-top-color: #2fc25b !important;
        border-bottom-color: #2fc25b !important;
    }
    .ArrowSmallRight3 {
        border-left-color: #2fc25b !important;
    }
    .lineBg3 {
        .contentTit {
            color: #fff !important;
        }
    }
    .optItemBox {
        min-width: 14%;
        height: 30px;
        position: relative;
        display: inline-block;
        vertical-align: top;
        font-size: 12px;
        margin-right: 10px;
        margin-left: 10px;
        border-width: 1px 0 1px 1px;
        border-style: solid;
        line-height: 30px;
        padding-left: 3px;
        padding-right: 3px;
        .tips {
            display: none;
            word-break: break-all;
            position: absolute;
            left: -29px;
            // width: 280px;
            background: #5c5c5c;
            line-height: 20px;
            padding: 5px;
            border-radius: 3px;
            color: #fff;
            top: 45px;
        }
        .orderIcon {
            display: inline-block;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border-width: 1px;
            border-style: solid;
            line-height: 18px;
            text-align: center;
            position: absolute;
            left: 5px;
            top: 5px;
            font-size: 16px;
            i {
                font-size: 12px;
            }
        }
        .optItemContentBox {
            font-size: 12px;
            .contentTit {
                font-weight: 500;
                font-size: 10px;
                height: 16px;
                color: #fff;
                .iconBox {
                    position: absolute;
                    right: 10px;
                    display: none;
                }
            }
            > div {
                .iconfont {
                    font-size: 12px;
                }
            }
            .lastContent {
                display: inline-block;
                min-width: 80px;
                max-width: 100%;
                padding-right: 80px;
                position: relative;
                .time {
                    position: absolute;
                    right: 5px;
                    top: 0px;
                    height: 14px;
                    line-height: 15px;
                }
            }
        }
        .box01 {
            width: 0;
            height: 0;
            border-top: 15px solid;
            border-bottom: 15px solid;
            border-left: 17px solid transparent;
            position: absolute;
            left: -18px;
            z-index: 1;
            top: -1px;
            .child {
                width: 0px;
                height: 0px;
                border-top: 14px solid;
                border-bottom: 14px solid;
                border-left: 15px solid transparent;
                position: absolute;
                top: -14px;
                left: -14px;
            }
        }
        .box02 {
            width: 0;
            height: 0;
            border-top: 15px solid transparent;
            border-bottom: 15px solid transparent;
            border-left: 17px solid;
            position: absolute;
            right: -17px;
            z-index: 1;
            top: -1px;
            .child {
                width: 0px;
                height: 0px;
                border-top: 14px solid transparent;
                border-bottom: 14px solid transparent;
                border-left: 15px solid;
                position: absolute;
                top: -14px;
                left: -17px;
            }
        }
    }
</style>
