<template>
    <div class="addVue">
        <div class="contact_title_box">
            <!-- 关联商品 -->
            <div class="contact_title">{{$t('mx_mobile.common.1665817307774')}}</div>
            <div class="add-contact">
                <!-- 添加 -->
                <van-button type="primary" @click="addList" size="small">{{$t('mx_mobile.common.1665817395413')}}</van-button>
            </div>
        </div>
        <!-- 商品大类 点击选商品大类 -->
        <van-field readonly clickable name="picker" :value="goodsType.catgName"  :label="$t('mx_mobile.common.1665817419709')" :placeholder="$t('mx_mobile.common.1665817534277')"  @click="showPicker = true"/>
        <van-popup v-model="showPicker" position="bottom">
            <van-picker show-toolbar :columns="classTypeInUseList" @confirm="onConfirm" @cancel="showPicker = false" value-key="catgName"/>
        </van-popup>
        <div v-if="goodsData.length > 0">
            <template v-for="(item,index) in goodsData">
                <div :key="item.rowKey">
                    <div class="contact_title_box" :key="item.rowKey">
                        <!-- 商品 -->
                        <div class="contact_title">{{$t('mx_mobile.App.1596628342416')}}_{{index+1}}</div>
                        <div>
                            <!-- 删除 -->
                            <van-button type="primary" @click="deleteList(index)" size="small">{{$t('mx_mobile.Client.1585301969072')}}</van-button>
                        </div>
                    </div>
                    <div v-for="ele in editSet" :key="ele.fieldId">
                        <!-- 是否在库 -->
                        <van-field name="switch" :label="$t('mx_mobile.common.1665817664986')" v-if="ele.fieldId === 3194">
                            <template #input>
                                <van-switch :value="item.instock === '1'" size="20" @input="switchChange($event, item)"/>
                            </template>
                        </van-field>
                        <template v-else-if="ele.controlTypeId == 122">
                        <van-field v-if="item.instock === '1'" :value="item.spuName" readonly clickable :label="ele.cnFieldCaption" @click="showChangeGood(item, ele)"/>
                        </template>
                        <template v-else-if="ele.fieldId == 3198">
                        <van-field v-if="item.instock === '0'" v-model="item[ele.fieldName]" clickable :label="ele.cnFieldCaption"/>
                        </template>
                        <van-field v-else-if="ele.fieldId === 3232" v-model="item[ele.fieldName]" :label="ele.cnFieldCaption" type="digit"/>
                        <sysBoxItem v-else-if="ele.controlTypeId == 55" :itemData="ele" :sysBoxValue="sysBoxValue" :option="unitList" valueKey="name" @changeInput="changeUnit($event, item, ele.fieldName)" :value="item[ele.fieldName]" :unitMap="unitMap"></sysBoxItem>
                        <van-field v-else-if="ele.controlTypeId == 43" v-model="item[ele.fieldName]" clickable :label="ele.cnFieldCaption"/>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>
<script>
import sysBoxItem from './sysBoxItem.vue'
import selectCountryEvent from '@/components/SelectGood/event.js'
import { getUUID } from '@/libs/utils.js'
export default {
    name: 'goodsList',
    props: {
        editSet: {
            type: Array,
            default: function () {
                return []
            }
        },
        moduleCode: {
            type: String,
            default: ''
        },
        optCode: {
            type: String,
            default: 'otNew'
        },
        sysBoxValue: {
            type: Array,
            default: () => {
                return []
            }
        },
        cusBoxValue: {
            type: Array,
            default: () => {
                return []
            }
        },
        goodsType: {
            type: Object,
            default: () => {
                return {}
            }
        },
        classTypeInUseList: {
            type: Array,
            default: () => {
                return []
            }
        },
        unitList: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    data() {
        return {
            goodsData: [],
            showPicker: false
        }
    },
    computed: {
        unitMap() {
            let obj = {}
            this.unitList.forEach(ele => {
                obj[ele.unitId] = ele.name
            })
            return obj
        }
    },
    created() {
        this.goodObj = {}
        this.goodsData = []
        this.editSet.forEach(ele => {
            this.goodObj[ele.fieldName] = ele.fieldValue
        })
    },
    methods: {
        submit() {
            this.goodsData.map((ele, index) => {
                ele.orderNo = index + 1
            })
            return this.goodsData
        },
        switchChange(value, item) {
            if (value) {
                item.instock = '1'
            } else {
                item.instock = '0'
            }
            item.spuId = ''
            item.spuCode = ''
        },
        async showChangeGood(item, field) {
            let obj = {
                spuName: '',
                spuId: item.spuId || ''
            }
            let arr = await this.getGoodList()
            await this.showGoodList(obj, arr, item, field)
        },
        async showGoodList(obj, arr, item, field) {
            let goodData = {}
            try {
                goodData = await selectCountryEvent.show(obj, arr)
                if (goodData?.type === 'search') {
                    this.getSearchGoodList(goodData.keywords, item, field)
                } else {
                    item[field.fieldName] = goodData[field.fieldName]
                    this.returnFieldList(goodData, field.returnFieldList, item)
                }
            } catch (error) {
                goodData = {
                    spuName: '',
                    spuId: ''
                }
            }
        },
        returnFieldList(data, field, item) {
            let fieldList = field.split(';')
            fieldList.forEach(element => {
                if (element != '') {
                    let elementList = element.split('=')
                    if (elementList[0] && elementList[1]) {
                        this.$set(item, elementList[0], data[elementList[1]])
                    }
                }
            })
        },
        async getSearchGoodList(keywords, item, field) {
            let obj = {
                spuName: '',
                spuId: ''
            }
            let list = await this.getGoodList(keywords)
            await this.showGoodList(obj, list, item, field)
        },
        async getGoodList(keywords) {
            let list = []
            try {
                let params = {
                    moduleCode: 'PP001',
                    searchType: 'list',
                    strucId: 1,
                    from: 0,
                    size: 10,
                    supplierNature_not: 2,
                    offFlag: 0,
                    category: this.goodsType.catgId
                }
                keywords && (params.keywords = keywords)
                let url = this.Global.config.apiBaseURL + this.Global.api.v2.document_product
                let res = await this.axios.get(url, {
                    params: params
                })
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    list = res.data.data.list
                } else {
                    this.loading = false
                    this.$toast.fail(res.data.msg)
                }
            } catch (error) {
                this.loading = false
                this.$toast.fail(this.Global.config.errorTitle)
            }
            return list
        },
        changeUnit(obj, itemData, fieldName) {
            itemData[fieldName] = obj.unitId + ''
        },
        onConfirm(value) {
            this.$emit('changeCategory', value)
            this.showPicker = false
            if (this.goodsType.catgId !== '') {
                this.goodsData = []
            }
        },
        addList() {
            let obj = JSON.parse(JSON.stringify(this.goodObj))
            obj.rowKey = getUUID()
            this.goodsData.push(obj)
        },
        deleteList(index) {
            this.goodsData.splice(index, 1)
        }
    },
    components: Object.assign({
        'sysBoxItem': sysBoxItem
    })
}
</script>

<style lang="less" scoped>
.addVue {
    .margin-bottom(10px);
    width: 100%;
    background: #fff;
    .contact_title_box {
        display: flex;
        justify-content: space-between;
        padding: 0 15px;
        background: #f5f5f5;
        .height(45px);
        .line-height(45px);
    }
}
</style>
