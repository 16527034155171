<template>
    <div>
        <!-- 暂无数据 -->
        <nodata v-if="!itemData._convertData.strucId_3 || itemData._convertData.strucId_3.length === 0" img="noData" :title="$t('mx_mobile.Doc.1585191499748')"></nodata>
        <template v-else>
            <div>
                <!-- 关联商品 -->
                <div class="baseMsgTitle">{{$t('mx_mobile.common.1665817307774')}}</div>
                <div class="listContent">
                    <!-- 商品大类 -->
                    <span class="contentLeft">{{$t('mx_mobile.common.1665817419709')}}</span>
                    <span>{{itemData._convertData.category || ''}}</span>
                </div>
            </div>
            <template v-for="(ele, index) in itemData._convertData.strucId_3">
                <span :key="ele.cId">
                    <!-- 商品序号 -->
                    <div class="baseMsgTitle">{{$t('mx_mobile.common.1665825276965')}}_{{index+1}}</div>
                    <div class="listContent">
                        <!-- 是否在库 -->
                        <span class="contentLeft">{{$t('mx_mobile.common.1665817664986')}}</span>
                        <span>{{ele.instock || ''}}</span>
                    </div>
                    <div class="listContent">
                        <!-- 商品 -->
                        <span class="contentLeft">{{$t('mx_mobile.App.1596628342416')}}</span>
                        <div class="contentEllipsis">{{ele.spuName || ''}}</div>
                    </div>
                    <div class="listContent">
                        <!-- 需求量 -->
                        <span class="contentLeft">{{$t('mx_mobile.common.1665825467547')}}</span>
                        <div class="contentEllipsis">{{ele.needAmt || ''}}</div>
                    </div>
                    <div class="listContent">
                        <!-- 计量单位 Measurement unit -->
                        <span class="contentLeft">{{$t('mx_mobile.common.1665825498428')}}</span>
                        <div class="contentEllipsis">{{ele.unit || ''}}</div>
                    </div>
                    <div class="listContent">
                        <!-- 商品链接 -->
                        <span class="contentLeft">{{$t('mx_mobile.common.1665974288928')}}</span>
                        <div class="contentEllipsis">{{ele.webLink || ''}}</div>
                    </div>
                </span>
            </template>
        </template>
    </div>
</template>
<script>
import Controls from '@/page/Client/CustPublic/Controls/index.js'
import GroupControls from '@/page/Client/CustPublic/GroupControls/index.js'
export default {
    name: 'baseMsg',
    props: {
        editSet: {
            type: Array,
            default: function () {
                return []
            }
        },
        moduleCode: {
            type: String,
            default: ''
        },
        itemData: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
        }
    },
    components: Object.assign({}, Controls, GroupControls)
}
</script>
<style lang="less" scoped>
.baseMsgTitle {
    height: 35px;
    padding: 0 15px;
    line-height: 35px;
}
.listContent {
    padding: 0 15px;
    line-height: 35px;
    margin-top: 1px;
    background: #fff;
    display: flex;
    flex-wrap: wrap;
    .contentLeft {
        width: 120px;
    }
    .contentEllipsis {
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 1;
    }
}
</style>
