<template>
    <div>
        <div v-for="item in editSet" :key="item.fieldId">
            <div v-if="item.fieldCategory === 4" class="baseMsgTitle">{{item.cnFieldCaption}}</div>
            <div v-else class="listContent">
                <span class="contentLeft">{{item.cnFieldCaption}}</span>
                <div class="contentEllipsis">{{itemData._convertData[item.fieldName] || ''}}</div>
            </div>
        </div>
        <div v-for="item in customerFields" :key="item.fieldId">
            <div v-if="item.fieldCategory === 4" class="baseMsgTitle">{{item.cnFieldCaption}}</div>
            <div v-else class="listContent">
                <span class="contentLeft">{{item.cnFieldCaption}}</span>
                <div class="contentEllipsis">{{itemData._convertData[item.fieldName] || ''}}</div>
            </div>
        </div>
        <!-- 联系人 -->
        <div class="baseMsgTitle">{{$t('mx_mobile.common.1665818691849')}}</div>
        <template v-for="(ele, index) in itemData._convertData.strucId_2">
            <span :key="ele.cId">
                <!-- 联系人 -->
                <div class="baseMsgTitle">{{$t('mx_mobile.common.1665818691849')}}_{{index+1}}</div>
                <div v-for="item in contactFields" :key="item.fieldId">
                    <div class="listContent">
                        <span class="contentLeft">{{item.cnFieldCaption}}</span>
                        <template v-if="item.controlTypeId == 41">
                            <component class="ControlsShow" v-bind:is="'ControlsShow'+item.controlTypeId" :itemData="item" :value="{value:ele[item.fieldName]}" ref="ControlsShow" :socials="socials"></component>
                        </template>
                        <div class="contentEllipsis"  v-else>{{ele[item.fieldName] || ''}}</div>
                    </div>
                </div>
            </span>
        </template>
    </div>
</template>
<script>
import ControlsShow from '@/page/Client/CustPublic/ControlsShow/index.js'
import Controls from '@/page/Client/CustPublic/Controls/index.js'
import GroupControls from '@/page/Client/CustPublic/GroupControls/index.js'
export default {
    name: 'baseMsg',
    props: {
        editSet: {
            type: Array,
            default: function () {
                return []
            }
        },
        moduleCode: {
            type: String,
            default: ''
        },
        customerFields: {
            type: Array,
            default: () => {
                return []
            }
        },
        itemData: {
            type: Object,
            default: () => {
                return {}
            }
        },
        contactFields: {
            type: Array,
            default: () => {
                return []
            }
        },
        socials: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    data() {
        return {
        }
    },
    methods: {
    },
    components: Object.assign({}, Controls, GroupControls, ControlsShow)
}
</script>
<style lang="less" scoped>
.baseMsgTitle {
    height: 35px;
    padding: 0 15px;
    line-height: 35px;
}
.listContent {
    padding: 0 15px;
    line-height: 35px;
    margin-top: 1px;
    background: #fff;
    display: flex;
    .contentLeft {
        width: 120px;
    }
    .contentEllipsis {
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 1;
    }
}
</style>
